<template>
  <PageCard
    pageName="FI Prospecting & CRM"
    :iconClass="['cuis-target', 'card-icon-font']"
  >
    <template slot="page_content">
      <b-row class="about-row">
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FIN Technographics</div>
            <b-card-body class="p-0">
              <div>
                Get unparalleled insight into the technologies and digital
                offerings deployed by all U.S. financial institutions.
                Understand their buying habits, integration constraints, vendor
                diversity, and next-most-likely solutions.
              </div>
              <b-card-img
                src="../../../../static/img/solution-report.png"
                class="rounded-0 my-2"
              />
              <div class="mb-2">
                Search for institutions that have (or don’t have) specific
                retail, business or operating solutions from a hierarchy of
                nearly 100 solution categories. Or search for specific
                vendor/product critical to your solution’s integration (ex. core
                processing) or where your solution matches up favorably.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/technographics/product/search' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FIN Query</div>
            <b-card-body class="p-0">
              <div>
                Deploy our advanced FIN Query to harness the powerful
                combination of technographics, performographics and
                firmographics for the most precise FI prospecting available
                anywhere.
              </div>
              <b-card-img
                src="../../../../static/img/fin-advanced-query.png"
                class="rounded-0 my-2"
              />
              <div class="mb-2">
                Design a query that will precisely identify those institutions
                where your product is a solution to an existing performance
                and/or offering deficiency filtered by institution type, size,
                geography or other firmographic variables. Choose the
                firmographics, technographics, performance metrics or contact
                information to export to kick off your campaign.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/technographics/fin_query/query' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FIN VendorFit</div>
            <b-card-body class="p-0">
              <div>
                Leverage FIN VendorFit to rank core processing vendor-product
                fit for any institution. The regression analysis integrates over
                100 independent variables covering all institutions’ size,
                market, competition, profitability, growth, risk, retail
                offering, business offering, strategy, productivity and more to
                identify better solution fits.
              </div>
              <b-card-img
                src="../../../../static/img/vendor_fit.png"
                class="rounded-0 my-2"
              />
              <div class="mb-2">
                Select any institution and quickly visualize how it matches up
                with the client base of any selected core solution. Leverage the
                analysis to identify higher probability core conversion
                candidates and to graphically make the case for alternative
                solutions.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/fi_prospect/vendor_fit/select_institution' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'ProspectList',
  components: {
    PageCard
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FI Prospect List'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped></style>
